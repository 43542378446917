<app-home [titre]="title"
          [homeApplication]="null"
          [menu]="menu"
          [anonymous]="false"
          [avatar]="false"
          [miniSidenav]="false"
          [darkTheme]="false" version="(2.0.1)">
</app-home>
<p8-spinner></p8-spinner>

