import {Inject, Injectable, InjectionToken, Injector, Optional} from '@angular/core';
import {environment} from '@Env/environment';
import {
  AccessTo,
  AccessToApplication,
  canConnectToApplication, Individu,
  IndividuExterne,
  IndividuExterneAskResetPwd, IndividuExterneResetPwd
} from './individu.model';
import {AuthService} from '@Services/auth';
import {HttpClient} from '@angular/common/http';
import {DaoGeneric} from "@Services/base/daoService";
import {filter, mergeMap, switchMap} from "rxjs/operators";
import {IndividuService} from "@Common/core/services";
import {Observable, of, ReplaySubject} from "rxjs";


export const accessToApplication = new InjectionToken<AccessToApplication>('AccessToApplication');

/**
 * Ajouter {provide: accessToApplication, useValue: AccessToApplication.<application>} dans app.module.ts
 *
 * */
@Injectable({ providedIn: 'root' })
export class IndividuExterneService extends DaoGeneric<any> implements canConnectToApplication {

  private urlBase = `${environment.baseUrl}`;
  private url = `${this.urlBase}/gestion_universite/external-access/`;

  private individuSource = new ReplaySubject<IndividuExterne>(1);
  individu$ = this.individuSource.asObservable();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    @Inject(accessToApplication) private application: AccessToApplication) {
    super(httpClient);

    console.log('IndividuExterneService appli: ', this.application);
    this.authService.userActivate$
      .pipe(filter(user => !!user), mergeMap(user => this.get(user.username)))
      .subscribe(individu => {
        console.log('IndividuExterneService.userActivate$');
        this.individuSource.next(individu as IndividuExterne);
      });
  }

  getRootUrl(): string {
    return this.url;
  }

  canConnectToApplication() {
    return this.httpClient.get<AccessTo>(`${this.url}${this.application}/`);
  }

  get(id, stripEnd = false) {
    return this.canConnectToApplication()
      .pipe(
        switchMap(
          (accessTo) => {
            return of(accessTo.individu);
          }
        ));
  }
}

@Injectable({ providedIn: 'root' })
export class IndividuExternePasswordService  {
  private urlBase = `${environment.baseUrl}`;

  constructor( private httpClient: HttpClient) {}

  askReset(param: IndividuExterneAskResetPwd) {
    const url = `${this.urlBase}/gestion_universite/external-individu/ask_reset/`;
    return this.httpClient.post(url, param);
  }

  resetPwd(param: IndividuExterneResetPwd) {
    const url = `${this.urlBase}/gestion_universite/external-individu/reset_pwd/`;
    return this.httpClient.post(url, param);
  }
}


@Injectable({providedIn: 'root'})
export class IndividuFactoryService {
  /*
  Si on utilise la factory pour récupérer le service, il faut utiliser l'observable individu$ de la factory
  Sinon problème de compilation si on rajoute des champs dans IndividuExterne qui ne sont pas dans Individu

  Méthode alternative :
  dans le code utilisant la factory faire
    const individu$: Observable<Individu | IndividuExterne> = this.individuSvc.individu$;
    individu$.subscribe(...)
  au lieu de
    this.individuSvc.individu$.subscribe(...) qui risque de provoquer une erreur de compilation
  */
  private readonly service: IndividuService | IndividuExterneService = null;
  individu$: Observable<Individu | IndividuExterne> = null;

  constructor(private injector: Injector, @Optional() @Inject(accessToApplication) private application: AccessToApplication) {
    console.log('IndividuFactoryService.constructor: ', this.application);
    if (this.application) {
      this.service = this.injector.get<IndividuExterneService>(IndividuExterneService);
    } else {
      this.service = this.injector.get<IndividuService>(IndividuService);
    }
    this.individu$ = this.service.individu$;
  }

  public individuService() {
    return this.service;
  }
}
