import {ColumnDataTable, DataTableHeaderComponentService} from "@Common/widgets/general/data-table";
import {CandidatureAter} from "@Common/apps/ater/services/candidature/candidature.model";
import {noop} from "rxjs";
import * as dateFnsAdaptor from 'date-fns';
import {fr} from "date-fns/locale";
import {MatDataSourceGeneric} from "@Services/base/daoService";
import {CandidatureStatusComponent} from "@Common/apps/ater/components/candidature-status/candidature-status.component";

export abstract class CandidatureTable {
  dsCandidatures: MatDataSourceGeneric<CandidatureAter> = new MatDataSourceGeneric<CandidatureAter>();
  extraParams: Map<string, string> = new Map<string, string>();

  columns: ColumnDataTable[] = [
    {
      columnDef: 'reference', header: 'Référence',
      display: (element: CandidatureAter) => element?.reference,
      tooltip: (element) => element?.reference,
      sort: true
    },

    {
      columnDef: 'offre__numero', header: 'Numéro de l\'offre',
      display: (element: CandidatureAter) => element?.offre_obj?.numero,
      tooltip: (element) => element?.offre_obj?.numero,
      sort: true
    },

    {
      columnDef: 'offre__profil', header: 'Profil',
      display: (element: CandidatureAter) => element?.offre_obj?.profil,
      tooltip: (element) => element?.offre_obj?.profil,
      sort: true
    },
    {
      columnDef: 'dt_created', header: 'Créé le', display: (element: CandidatureAter) => this.shortFormatDate(element?.dt_created),
      sort: true
    },
    {
      columnDef: 'date_envoi', header: 'Envoyée le', display: (element: CandidatureAter) => this.shortFormatDate(element?.date_envoi),
      sort: true
    },
    {
      columnDef: 'statut', header: 'Statut de la candidature',
      display: (element: CandidatureAter) => noop(),
      sort: false
    },
  ];

  protected constructor(
    protected dataTableHeaderSvc: DataTableHeaderComponentService,
  ) {}

  protected shortFormatDate(date) {
    if (date) {
      return dateFnsAdaptor.format(new Date(date), 'dd MMM, yyyy', { locale: fr });
    }
    return null;
  }

  protected _setCellStatus() {
    const data = {
      role: 'candidat'
    };
    this.dataTableHeaderSvc
      .createColumnComponent(
        this.columns, 'statut', `statut_candidature`, `statut de la candidature`,
        CandidatureStatusComponent, data);
  }

}
