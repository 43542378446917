import {Injectable} from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';
import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ModelPjAter, ModelPjType} from './model-pj.model';


@Injectable({
  providedIn: 'root'
})
export class ModelPjAterService extends DaoGeneric<ModelPjAter> {
  private urlUpload = `${environment.baseUrl}/ater/model_pj/`;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(urlApp?: string): string {
    return this.urlUpload;
  }

  uploadModel(pj: File, type: ModelPjType = ModelPjType.comite): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', pj);
    formData.append('file_name', pj.name);
    formData.append('type', type.toString());
    return this.httpClient.post(this.urlUpload, formData);
  }


  deleteModel(fileId: number) {
    const url = `${this.urlUpload}${fileId}/`;
    return this.httpClient.delete(url);
  }

  private makeHttpRequest(urlSuffix: string, params = null): Observable<Blob> {
    const url = `${this.urlUpload}${urlSuffix}`;
    return this.httpClient.get<Blob>(url, { responseType: 'blob' as 'json', params });
  }

  getModelPj(id: number): Observable<Blob> {
    return this.makeHttpRequest(`${id}/download_pj/`);
  }

  getModelPjZip(type: ModelPjType): Observable<Blob> {
    const params = {
      type: type.toString()
    };
    return this.makeHttpRequest('download_zip_pj/', params);
  }
  isModelsExist(type: ModelPjType) {
    const url = `${this.getRootUrl()}is_models_exist/`;
    const params = {
      type: type.toString()
    };
    return this.httpClient.get(url, {params});
  }
}
