export enum ModelPjType {
  comite = 1,
  candidat = 2
}

export interface ModelPjAter {
  id?: number;
  file_name?: string;
  file?: File;
  type?: ModelPjType;
  dt_created?: Date;
}
