<p8-layout-settings-page>
  <mat-card>
    <mat-card-title>
      <p8-layout-settings-header [titlePath]="['Mes candidatures']"
                                 [iconRacine]="'inventory_2'"></p8-layout-settings-header>
    </mat-card-title>
    <mat-card-content fxLayout="column">
      <div fxLayoutAlign="end">
        <mat-card-actions align="end">
          <app-button-models-candidat-zip></app-button-models-candidat-zip>
        </mat-card-actions>
      </div>
      <div>
        <app-data-table
          [borderHeader]="false"
          [colorHeader]="'#444444'"
          [colorHeaderBackground]="'#eaeaea'"
          [actions]="actions"
          [columns]="columns"
          [dataSource]="dsCandidatures"
          [extraParams]="extraParams"
          [filterDisplay]="true"
          [flexAction]="5"
          [flexColumn]="90"
          [fontSizeCell]="'0.9em'"
          [minFilter]="1" [pageSize]="10"
          [pageSizesList]="[5,10,25]" [placeHolderFilter]="'Recherche de candidatures'"
          [refreshButton]="true"
          [refreshIcone]="false"
          [showFirstLastButtons]="true"
          [showLoader]="true"
          [loaderMask]="true"
          [toolTipFilter]="'Recherche de candidatures'"
          [filtersLegend]="true">
        </app-data-table>
      </div>
    </mat-card-content>
  </mat-card>
</p8-layout-settings-page>
