import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent implements OnInit {
  icon = 'remove_circle_outline';
  message = 'Page non autorisée';

  constructor(private router: Router) {
    this.icon = this.router.getCurrentNavigation().extras?.state?.icon || this.icon;
    this.message = this.router.getCurrentNavigation().extras?.state?.message || this.message;
  }

  ngOnInit() {
  }

}
