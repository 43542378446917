import {Injectable} from '@angular/core';
import {of, ReplaySubject} from 'rxjs';
import {environment} from '@Env/environment';
import {AccessTo, canConnectToApplication, Individu} from './individu.model';
import {AuthService} from '@Services/auth';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class IndividuService implements canConnectToApplication {
  private urlBase = `${environment.baseUrl}`;
  private url = `${this.urlBase}/gestion_universite/individu/`;

  private individuSource = new ReplaySubject<Individu>(1);
  // private individuSource = new Subject<Individu>();
  individu$ = this.individuSource.asObservable();

  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {
    this.authService.userActivate$
      .pipe(filter(user => !!user), mergeMap(user => this.get(user.username)))
      .subscribe(individu => {
        console.log('IndividuService.userActivate$');
        this.individuSource.next(individu as Individu);
    });
  }

  create(individu: Individu) {
    return this.http.post<Individu>(this.url, individu).pipe(tap(data => this.list()));
  }

  /**
   * Obtient ou créé un Individu (synchro LDAP => Individu)
   *
   * @param {string} uid : l'uid LDAP
   * @param {string} urlApp : optionnel, permet de surcharger l'url de base, exemple /dar/individu/
   */
  getOrCreate(uid: string, urlApp?: string) {
    let url = '';
    if (urlApp) {
      url = `${this.urlBase}${urlApp}`;
    } else {
      url = `${this.url}`;
    }

    return this.http.get<Individu>(`${url}${uid}/get_or_create/`);
  }

  list(extraQS: Map<string, any> = null) {
    const options = this.getURLParams(extraQS);

    return this.http.get<Individu[]>(this.url, {params: options});
  }

  get(uid: string) {
    // const tokenExpirated = this.authService.loggedIn();
    return this.http
      .get<Individu>(`${this.url}${uid}/`)
      .pipe(catchError( (err: HttpErrorResponse ) => {
        if (err && err.status === 401 && err.error && 'detail' in err.error && err.error.detail === 'Signature has expired.') {
          console.error('An error occurred:', err);
          // this.authService.logout();
        }
        if (err && err.status === 404) {
          console.error('User existe mais n\'est pas un Individu issue de ldap', err);
          this.authService.logout();
          this.router.navigate(['/login'], {state: {parentUrl: '/'}});
        }
        return of(null);
      }));
  }

  update(individu: Individu) {
    return this.http.put<Individu>(`${this.url}${individu.uid}/`, individu);
  }

  delete(individu: Individu) {
    return this.http.delete<Individu>(`${this.url}${individu.uid}/`);
  }

  hasPermission(individu: Individu, application_id: string, code: string []): boolean {
    if (individu && 'list_permissions' in individu) {
      const list_permissions = individu.list_permissions;
      for (const permission of list_permissions) {
        if (permission.application_id === application_id && code.includes(permission.code)) {
          return true;
        }
      }
    }
    return false;
  }

  hasRole(individu: Individu, role: string): boolean {
    if (individu && 'list_roles_entite' in individu) {
      for (const role_entite of individu.list_roles_entite) {
        if (role === role_entite.code) {
          return true;
        }
      }
    }

    return false;
  }

  getIdRole(individu: Individu, role: string): number {
    for (const role_entite of individu.list_roles_entite) {
      if (role === role_entite.code || role_entite.code == 'gestionnaire-adm') {
        return role_entite.id;
      }
    }
    return null;
  }

  private getURLParams(extraQS) {
    let urlQS = {};

    if(extraQS !== null) {
      extraQS.forEach((v,k) => {
        urlQS[k]= v;
      })
    }

    return urlQS;
  }

  canConnectToApplication() {
    return of(<AccessTo>{application: null, is_active: true});
  }
}
