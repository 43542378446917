import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModelPjType} from '@Common/apps/ater/services/models-pj/model-pj.model';
import {saveAs} from 'file-saver';
import {ModelPjAterService} from '@Common/apps/ater/services/models-pj/model-pj-file.service';
import {SubSink} from '@Common/core/utils/subsink';
import * as dateFnsAdaptor from 'date-fns';
import {fr} from 'date-fns/locale';
import {CommonModule} from '@angular/common';
import {MatAngularModule} from '@Common/mat-angular.module';

@Component({
  standalone: true,
  imports: [CommonModule, MatAngularModule],
  selector: 'app-button-models-candidat-zip',
  templateUrl: './button-models-zip-candidat.component.html',
  styleUrls: ['./button-models-zip-candidat.component.scss'],
})
export class ButtonModelsZipCandidatComponent implements OnInit, OnDestroy {
  modelsExist = false;
  subSink = new SubSink();
  constructor(private pjSvc: ModelPjAterService) { }

  ngOnInit(): void {
    this.subSink.sink = this.pjSvc
      .isModelsExist(ModelPjType.candidat)
      .subscribe(_data => this.modelsExist = _data['is_exist']);
  }
  exportZipModelCandidats() {
    this.subSink.sink = this.pjSvc
      .getModelPjZip(ModelPjType.candidat)
      .subscribe((data: Blob) => {
        const fileName = `Modeles-fichiers-types-candidat-${this._formatDateForZip()}.zip`;
        saveAs(data, fileName);
      });
  }
  private _formatDateForZip() {
    return dateFnsAdaptor.format(new Date(), 'yyyy-MM-dd-HH-mm', { locale: fr });
  }
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
