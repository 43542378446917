import {Component, OnInit} from '@angular/core';
import {SubSink} from '@Common/core/utils/subsink';
import {PubSubService} from '@Common/core/services';

@Component({
  selector: 'p8-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  showLoader = false;
  subSink = new SubSink();
  constructor(private pubSubSvc: PubSubService) {
  }

  ngOnInit(): void {
    this.subSink.sink = this.pubSubSvc.on('loading').subscribe((val) => setTimeout(() => this.showLoader = val));
  }
}
