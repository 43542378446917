import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {AppRoutingModule} from './app-routing';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {MatAngularModule} from "@Common/mat-angular.module";
import {HandlerError} from '@Common/handler-error';
import {CommonModuleP8} from "@Common/common-p8.module";
import {CoreModule} from "@Common/core/core.module";
import {AccueilComponent} from './accueil/accueil.component';
import {AccessToApplication, accessToApplication} from "@Common/core/services";
import {AterCommonModule} from '@Common/apps/ater/ater-common.module';
import {CandidaturesComponent} from "app/ater-candidatures/candidatures/candidatures.component";
import {ButtonModelsZipCandidatComponent} from './common/button-models-zip/button-models-zip-candidat.component';


registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    CandidaturesComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    CommonModuleP8,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatAngularModule,
    // Custom
    AppRoutingModule,
    MatAngularModule,
    AterCommonModule,
    ButtonModelsZipCandidatComponent
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: ErrorHandler, useClass: HandlerError},
    {provide: accessToApplication, useValue: AccessToApplication.ater_candidature}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
