import { Permission } from './permission.model';
import { RoleStructure } from './role-structure.model';
import {Observable} from "rxjs";

export interface Individu {
  uid: string;
  dn: string;
  supann_emp_id: string;
  supann_etu_id: string;
  email_pro: string;
  email_perso: string;
  user: number;
  last_name: string;
  first_name: string;
  username: string;
  list_roles_entite: RoleStructure[];
  list_permissions: Permission [];
  phone_pro?: string;
}


export interface IndividuExterne {
  id?: number;
  user?: number;
  last_name?: string;
  first_name?: string;
  username?: string;
}


export interface IndividuExterneAskResetPwd {
  username?: string;
  redirect_url?: string;
  application?: AccessToApplication;
}


export interface IndividuExterneResetPwd {
  username?: string;
  reset_code?: string,
  password?: string,
}


export enum AccessToApplication {
  ater_candidature = 1,
}
export namespace AccessToApplication {
  export function toString(ata: AccessToApplication): string {
    switch(ata) {
      case AccessToApplication.ater_candidature:
        return 'ATER Candidatures';
      default:
        return '';
    }
  }
}


export interface AccessTo {
  id?: number;
  individu?: IndividuExterne;
  application?: AccessToApplication;
  is_active?: boolean;
}


export interface canConnectToApplication {
  canConnectToApplication: () => Observable<AccessTo>;
}
