import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@Services/auth';
import { AuthService } from '@Services/auth';
import {Menu} from '../models/menu.model';
import {ComponentItem} from './dynamic-core-components/component-item';
import {IndividuFactoryService} from '@Common/core/services';
import {ComponentsService} from '@Services/navbar/components-navbar.service';
import {BehaviorSubject} from 'rxjs';
import { filter } from 'rxjs/operators';
import {SubSink} from "@Common/core/utils/subsink";


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  user: User;
  individu: any = {};

  @Input() titre: string;
  @Input() version: string;
  @Input() menu: Menu[];
  @Input() opened = true;
  @Input() displayLogon = true;
  @Input() homeApplication = '/';
  @Input() avatar = true;
  @Input() assetsSource = 'assets';
  @Output() open = new EventEmitter<Boolean>();

  components: BehaviorSubject<ComponentItem[]>;
  subSink = new SubSink();
  defaultLogo: string;

  constructor(private authService: AuthService, private individuFactoryService: IndividuFactoryService, private router: Router,
              private compsService: ComponentsService,
              private cdr: ChangeDetectorRef) {
    this.components = new BehaviorSubject([]);
  }

  get displayName() {
    return this.individu && this.individu.last_name ? `${this.individu.first_name} ${this.individu.last_name}`  : '';
  }

  ngOnInit() {
    this.defaultLogo = (typeof this.assetsSource === 'undefined') ? `assets/logop8_blanc.png` : `${this.assetsSource}/logop8_blanc.png`;

    this._componentInit();
    this.subSink.sink = this.individuFactoryService.individu$
      .subscribe((ind) => {
      if (ind) {
        Object.assign(this.individu, ind);
      }
    });

    // TODO : sendUser() à retirer sans doute (après vérification) : possible effet de bord avec autenticated() appelé dans login component
    this.authService.sendUser();
    this.subSink.sink = this.authService.userActivate$.pipe(filter(user => !!user)).subscribe(user => this.user = user);
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  iconMenu() {
    return this.opened ? 'chevron_left' : 'chevron_right';
  }
  clickOpen() {
    this.opened = !this.opened;
    this.open.emit(this.opened);
  }
  private _componentInit() {
    // lecture des components à afficher lorsqu'ils seront envoyés au service par un module d'application
    this.subSink.sink = this.compsService.componentActivate$.subscribe((components) => {
      this.components.next(components);
    });
    this.cdr.detectChanges();
  }
}
