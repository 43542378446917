import { Component, OnInit } from '@angular/core';
import { environment } from '@Env/environment';

@Component({
  selector: 'p8-disclaimer-test',
  templateUrl: './disclaimer-test.component.html',
  styleUrls: ['./disclaimer-test.component.scss']
})
export class DisclaimerTestComponent implements OnInit {
  environment = environment;
  constructor() { }

  ngOnInit(): void {
  }

}
