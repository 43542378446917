<app-navbar *ngIf="loggedIn()"
            [opened]="opened" (open)="opened=$event" [titre]="titre" [displayLogon]="displayLogon" [menu]="menu"
            [homeApplication]="homeApplication" [avatar]="avatar"  [assetsSource]="assetsSource"
            [version]="version"></app-navbar>

<mat-sidenav-container [autosize]="autosize"
                       class="app-cm__home_sidenav-container"
                       fullscreen
                       (window:resize)="toggleSidenav($event)">
  <mat-sidenav *ngIf="loggedIn()"
               #sidenav
               class="app-cm__home_side-nav mat-elevation-z6 global__mat-sidenav-custom"
               [mode]="sidenavMode"
               [opened]="opened"
               (openedChange)="toggleOpened($event)"
               [ngClass]="{'global__mat-sidenav-custom': darkTheme}">

    <mat-nav-list>
      <mat-list-item routerLink="/"
                     [routerLinkActive]="'app-cm__home_sidenav-active'"
                     [routerLinkActiveOptions]="{ exact: true }"
                     matTooltip="Accueil"
                     [matTooltipDisabled]="!miniSidenav"
                     matTooltipPosition="right"
                     matTooltipClass="global__tooltip"
                     matTooltipShowDelay="250">
        <mat-icon matListIcon>home</mat-icon>
        <a matLine *ngIf="!miniSidenav"> Accueil </a>
      </mat-list-item>
      <mat-divider></mat-divider>
      <ng-container *ngFor="let m of menu">
        <mat-list-item [routerLink]="m.url"
                       [routerLinkActive]="'app-cm__home_sidenav-active'"
                       [routerLinkActiveOptions]="{ exact: false }"
                       [matTooltip]="m.toolTip || m.titre"
                       [matTooltipDisabled]="disableTooltipElementMenu(m)"
                       matTooltipPosition="right"
                       matTooltipClass="global__tooltip"
                       matTooltipShowDelay="250">
          <app-menuicon matListIcon *ngIf="m.icon!==''" [icon]="m.icon"></app-menuicon>
          <a matLine *ngIf="!miniSidenav"> {{ m.titre }} </a>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <mat-card class="alert-warning" *ngFor="let message of messages; let i=index">
      <mat-card-content fxLayout="row">
        {{ message.message }}
        <button mat-icon-button class="button-warning" fxFlex fxLayoutAlign="end" (click)="closeAlert(i)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
