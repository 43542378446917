import {Component, Input, OnInit} from '@angular/core';
import {CandidatureAterService} from '@Common/apps/ater/services/candidature/candidature-bo.service';
import {StatusCandidateAter} from '@Common/apps/ater/services/candidature/candidature.model';
import {ColumnComponent} from '@Common/widgets/general/data-table';
import {CandidatureAter} from '@Common/apps/ater/services/candidature/candidature.model';

/**
 * Affiche le statut avec une signalétique couleur
 * 2 usages :
 * - autonome, les Input() sont pris en compte
 * - dans une cellule data-table, data (@Input) / input (l'objet injecté) sont pris en compte
 */
@Component({
  selector: 'app-candidature-status',
  templateUrl: './candidature-status.component.html',
  styleUrls: ['./candidature-status.component.scss']
})
export class CandidatureStatusComponent implements OnInit, ColumnComponent {
  @Input() status: StatusCandidateAter;
  @Input() statusCase: 'lower' | 'upper' | 'same' = 'same';
  @Input() role: 'gestionnaire' | 'candidat';
  /** pour composant dynamique data-table **/
  name: string;
  column: string;
  data: any;
  input: CandidatureAter;
  constructor(public candidatureSvc: CandidatureAterService) { }

  ngOnInit(): void {
  }
  getStatusChoice() {
    return this.status || this.input?.statut;
  }
  getCaseStatus(statusText: string) {
    const statusCase = this.data?.statusCase || this.statusCase;
    switch (statusCase) {
      case 'lower':
        return statusText.toLowerCase();
      case 'upper':
        return statusText.toUpperCase();
      case 'same':
        return statusText;
    }
    return statusText;
  }
  getStatusLabel() {
    const role = this.role || this.data?.role;
    if (role === 'candidat') {
      return this.getCaseStatus(StatusCandidateAter.toStringCandidat(this.getStatusChoice()));
    }
    return this.getCaseStatus(StatusCandidateAter.toStringGestionnaire(this.getStatusChoice()));
  }
  public getIconIfToTreat() {
    let iconWaiting = '';
    const role = this.role || this.data?.role;
    if (role === 'gestionnaire' &&  (this.status === StatusCandidateAter.send || this.input?.statut === StatusCandidateAter.send)) {
      iconWaiting = '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;';
    }
    return iconWaiting;
  }
}
